.checkin__cohort-warning {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px;
  background-color: #ffe9ae;
  border: 1px solid orange;
  margin: 10px 10px 0;
  border-radius: 8px;
}

.checkin__cohort-warning .anticon {
  margin-right: 4px;
}
.checkin__cohort-warning h5 {
  margin-left: 4px;
}

.checkin__error {
  color: red;
}
.checkin__logo {
  display: flex;
  align-content: center;
  justify-content: center;
  margin-top: 50px;
}

.checkin__logo img {
  max-width: 80%;
  max-height: 80px;
  object-fit: contain;
}

.checkin__form {
  margin-top: 40px;
  text-align: center;
}

.checkin__header {
  padding: 15px 72px;
  margin-bottom: 10px;
}

.checkin__result-col {
  border-radius: 5px;
  border: 1px solid #d9d9d9;
}

.checkin__row {
  display: flex;
  justify-content: center;
  text-align: center;
}

.checkin__results .ant-result {
  padding: 20px;
}

.checkin__results .ant-result-icon > .anticon {
  font-size: 110px;
}

.checkin__results .ant-result-icon {
  margin-bottom: 10px;
}

.checkin__results .anticon-warning {
  font-size: 20px;
  top: 7px;
}

.results__studentId {
  font-size: 24px;
  line-height: 30px;
  margin-bottom: 5px;
}

.results__info h5 {
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 0;
}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  .checkin__header {
    padding: 15px;
  }
  .checkin__logo {
    margin-top: 20px;
  }
  .checkin__form {
    margin-top: 15px;
  }
}
