.dashboard__list-refreshing {
  position: absolute;
  top: 12px;
  left: calc(50% - 100px);
  background: @primary-color;
  color: white;
  padding: 3px 10px;
  border-radius: 5px;
  font-size: 11px;
  font-weight: bold;
}

.dashboard__list-refreshing-hidden {
  opacity: 0;
  transition: all 0.25s ease-in-out;
}

.dashboard__list-refreshing-visible {
  opacity: 1;
  transition: all 0.25s ease-in-out;
}

@primary-color: #0060b5;