.qrcode__container {
  margin: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 300px;
  flex-direction: column;
}

.qrcode__container h3 {
  margin-top: 30px;
  max-width: 400px;
  text-align: center;
}
