.dashboardhome__stats {
  margin-bottom: 20px;
}

.dashboardhome__stats-name {
  font-weight: normal;
}

.dashboardstats__row {
  font-size: 16px;
  margin-bottom: 3px;
}

.dashboardstats__card {
  min-height: 260px;
}
