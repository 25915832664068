.districts__header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.listheader__searchactions {
  display: flex;
  flex-direction: row;
}

.listheader__searchbar {
  margin-left: 15px;
}
