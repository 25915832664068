.step-description {
  margin-block-end: 10px;
  font-size: 17px;
  line-height: 21px;
  color: #1d1d1f;
}

.step-header {
  margin-bottom: 30px;
}
