.incidentform__confirm-desc {
  color: #888;
  font-size: 12px;
  font-weight: normal;
}

.usercard__info {
  margin-top: 10px;
}

.userprofile__answer .red b {
  color: red;
}

.userprofile__badge {
  cursor: pointer;
  max-width: 100px;
}

.userprofile__card {
  margin-top: 16px;
  margin-right: 15px;
  flex: 1;

  .ant-card-body {
    padding-top: 15px;
  }
}

.userprofile__card-data {
  display: flex;
  flex-direction: row;
}

.userprofile__card-name {
  flex: 1;
}

.userprofile__card:last-of-type {
  margin-right: 0;
}

.userprofile__card a {
  font-weight: normal;
}

.userprofile__card .ant-table-cell strong {
  color: @primary-color;
  font-weight: normal;
}

.userprofile__card .ant-table-row {
  cursor: pointer;
}

.userprofile__cards {
  display: flex;
  flex-direction: row;
}

.userprofile__cards .ant-card-actions {
  position: relative;
}

.userprofile__container {
  padding: 0 0 60px 0;
}

.userprofile__description {
  color: rgba(0, 0, 0, 0.45);
  margin-right: 20px;
}

.userprofile__homeschooldropdown {
  margin-top: 20px;
}

.userprofile__list-buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0 -20px 20px -20px;
  padding: 0 20px 10px 20px;
  border-bottom: 1px solid #f0f0f0;
}

.userprofile__list-count {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  p {
    color: #888;
    margin: 0;
  }

  span {
    font-size: 12px;
    color: #333;
    text-transform: capitalize;
  }
}

.userprofile__qrcode {
  width: 60px;
}

.userprofile__form-modal-choices {
  margin-top: 15px;
}

.userprofile__history {
  background-color: #ffffff;
  margin-top: 15px;
  padding: 20px;
}

.userprofile__history-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.userprofile__history h3 {
  margin-bottom: 2px;
  margin-right: 20px;
}

.userprofile__history-panel ul {
  margin-bottom: 0;
}

.userprofile__identifier {
  font-size: 16px;
  font-weight: 500;
}

.userprofile__notes-stats {
  margin-top: 20px;
}

.userprofile__response-selections {
  padding-left: 50px;
}

.userprofile__tag {
  align-items: center;
  cursor: pointer;
  display: flex;
  margin: 4px 0;
}

.userprofile__tag > div {
  flex: 1;
}

.userprofile__history-text {
  margin-left: 40px;
}

.userprofile__history-text-editable {
  cursor: pointer;
}

.userprofile__history-text-editicon {
  margin-left: 5px;
}

.userprofile__timeline-collapse > .ant-collapse-item > .ant-collapse-header {
  color: @primary-color;
  font-weight: bold;
  padding-top: 2px;
  padding-bottom: 2px;
}

.userprofile__timeline {
  padding: 5px;
}

.userprofile__timeline-container {
  background: #fff;
  padding: 20px;
  margin-top: 15px;
}

.userprofile__timeline-container h3 {
  margin-bottom: 20px;
}

.userprofile_timeline-pagination {
  display: flex;
  justify-content: flex-end;
}

@primary-color: #0060b5;