.results {
  width: 100%;
}

.results .ant-result-content {
  background: #fff;
}

.results .ant-result-icon > .anticon {
  font-size: 122px;
}

.results .ant-result-title {
  line-height: 1.5;
}

.results .ant-result-title h2 {
  margin-bottom: 15px;
}

.results .ant-result-title h2.results__name {
  margin-bottom: 0;
}

.results .ant-result-title small {
  display: block;
  margin: 30px 0;
  font-size: 16px;
  color: #828282;
}

.results__body {
  display: flex;
  font-size: 18px;
  line-height: 26px;
  margin-block-start: 50px;
  max-width: 960px;
  padding-left: 20px;
  padding-right: 20px;
  margin-left: auto;
  margin-right: auto;
}

.results__cohort-warning {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #ffe9ae;
  border: 1px solid orange;
  margin: 10px auto 0;
  border-radius: 8px;
  max-width: 300px;
}

.results__cohort-warning .anticon {
  margin-right: 4px;
  font-size: 32px;
}
.results .results__cohort-warning h2 {
  margin: 0 0 0 4px;
  margin-bottom: 0;
}

.results__date {
  margin-bottom: 0;
}

.results__graphic {
  height: 210px;
  position: absolute;
  left: 60px;
  bottom: 0;
}
.results__header {
  background: #fff;
  min-height: 210px;
  padding-top: 40px;
  position: relative;
}

.results__link {
  display: block;
  cursor: pointer;
  margin-top: 20px;
}

.results__nextsteps {
  flex: 1 1 550px;
}

.results__nextsteps h3 {
  margin-bottom: 10px;
}

.results_nextstep {
  border-bottom: 1px solid #eee;
  display: flex;
  padding: 20px 0;
}

.results_numbercircle {
  border-radius: 50%;
  width: 26px;
  height: 26px;
  padding: 2px;

  background: #000;
  border: 1px solid #000;
  color: #fff;
  text-align: center;

  font-size: 20px;
  font-weight: 600;
  line-height: 26px;
}

.results__responses {
  flex: 1 1 400px;
  min-width: 300px;
  padding-top: 20px;
}

.results__responses-list {
  background: #fff;
  border-radius: 10px;
  font-size: 14px;
  padding: 20px;
  text-align: left;
  margin-bottom: 30px;
  margin-top: 30px;
}

.results__responses-list li {
  list-style: none;
}

.results__responses-listitem {
  display: flex;
  align-items: center;
}

.results__responses-listicon {
  margin-right: 8px;
}

.results__responses-listicon-yes {
  color: red;
}

.results__responses-listicon-no {
  color: #52c41a;
}

.results__start-over {
  text-align: center;
  padding-bottom: 40px;
  font-weight: bold;
  margin: 0 15px;
}

.results__stepcol {
  margin-left: 15px;
}

.results__stepcol h3 {
  font-size: 18px;
  margin: 2px 0 15px;
}

.results__stepcol p {
  margin: 8px 0;
}

.results__stepsheader {
  margin-block-start: 50px;
  max-width: 960px;
  padding-left: 20px;
  padding-right: 20px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 50px;
}

.results__stepsheadertext {
  max-width: 50%;
}

.site-result-demo-error-icon {
  color: #6963f7;
}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  .results .ant-result {
    padding: 15px 28px;
  }
  .results .ant-result-title {
    font-size: 20px;
  }
  .results__header {
    padding-top: 0;
  }

  .results__responses {
    min-width: auto;
  }

  .results__responses-list {
    padding-left: 20px;
  }
}
