.districtsettings__container {
  margin-top: 15px;
  padding-bottom: 100px;
}

.districtsettings__screener-question {
  background-color: white;
  border-width: 1px;
  border-color: white;
  border-style: solid;
  margin-bottom: 15px;
}

.districtsettings__screener-question-drag {
  border-color: @primary-color;
}

@primary-color: #0060b5;