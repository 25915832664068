.container-li {
  background: #fff;
  border: 3px solid #fff;
  border-radius: 11px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  display: flex;
  font-weight: bold;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;
  margin-bottom: 16px;
  text-align: left;
  min-height: 88px;
  font-size: 16px;
}
.screener__listitem-disabled {
  color: gray;
  cursor: not-allowed;
}
.screener__listitem-last {
  background-color: #fff;
  font-size: 22px;
}
.li-checkmark {
  width: 20px;
  height: 20px;
}
.li-checkmark-off {
  width: 20px;
  height: 20px;
  fill: #ededed;
  opacity: 0.25;
}
.selected {
  border: 3px solid @primary-color;
}

@media only screen and (max-width: 767px) {
  /* For mobile phones: */
  .li-checkmark,
  .li-checkmark-off {
    margin-left: 20px;
  }
}

@primary-color: #0060b5;