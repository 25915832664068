.home__demo-links {
  width: 100%;
  margin-top: 30px;
  text-align: center;
}

.home__demo-links h5 {
  color: #666;
}

.home__demo-links-button {
  margin: 8px 0;
}
