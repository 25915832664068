.accountnotfound__row {
  padding-top: 40px;
  display: flex;
  justify-content: center;
  text-align: center;
}

.accountnotfound__container {
  padding: 0 20px;
}

.accountnotfound_mistake {
  margin-top: 40px;
}
