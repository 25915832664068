.dashboardtestresults__table td {
  font-size: 16px;
  padding-bottom: 3px;
}

.dashboardtestresults__table-row {
  color: @primary-color;
  cursor: pointer;
}

@primary-color: #0060b5;