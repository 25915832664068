.nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nav__links {
  font-weight: normal;
}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  .nav {
    flex-direction: column-reverse;
  }
  .nav__logo {
    margin-top: 15px;
  }
}
