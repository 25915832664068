.userslist__status {
  text-align: center;
}
.userslist_row {
  cursor: pointer;
}
.userslist__header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 10px;
}

.userslist__header-col {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.userslist__header-button {
  margin-right: 15px;
}

.userslist__header h1 {
  margin-bottom: 0;
}

.userslist__header small {
  color: gray;
  font-size: 13px;
}

.userslist__search {
  max-width: 300px;
}

.userslist__table {
  margin-top: 15px;
}
