.home {
  background-color: #f5f5f7;
  border: 1px solid #e3e3e5;
  border-radius: 15px;
  margin: 0 auto 0 auto;
  max-width: 800px;
  min-height: 500px;
  padding: 20px;
}

.home__inner {
  max-width: 750px;
  margin: 0 auto;
  padding: 40px;
  position: relative;
  width: 100%;
}

.home__inner h1 {
  font-weight: 600;
  font-size: 40px;
  line-height: 44px;
  margin-right: 150px;
}

.home__description {
  font-size: 22px;
  line-height: 28px;
  margin-right: 320px;
}

.home__description p {
  margin: 10px 0;
}

.home__button-container {
  max-width: 280px;
  margin-top: 60px;
}

.home__button {
  color: #fff;
  cursor: pointer;
  display: block;
  font-weight: 600;
  padding: 20px;
  background: @primary-color;
  border-radius: 11px;
  text-align: center;
  text-decoration: none;
  width: 100%;
}

.home__button-col {
  display: flex;
  flex-direction: column;
  align-items: center;

  small {
    margin: 10px 0;
    text-align: center;
  }
}

.home__graphic {
  position: absolute;
  top: 20px;
  right: 0;
  width: 300px;
}

.home__graphic-img {
  height: 550px;
}

.home__header {
  max-width: 800px;
  margin: 10px auto 10px;
  padding: 15px 0;
}

.home__school-logos {
  flex-direction: row;
}

.home__school-logo {
  height: 60px;
  margin: 15px 10px 0 10px;
}

@media only screen and (max-width: 767px) {
  /* For mobile phones: */
  .home {
    border-radius: 0;
    margin-top: 0;
  }
  .home__description {
    font-size: 17px;
    margin-right: 80px;
  }
  .home__graphic {
    top: auto;
    bottom: 180px;
    right: 0;
    width: 90px;
  }
  .home__header {
    margin-bottom: 10px;
    padding: 0;
  }
  .home__header .nav__logo {
    margin-top: 5px;
  }
  .home__inner {
    padding: 5px;
  }
  .home__inner h1 {
    font-size: 28px;
    line-height: 32px;
    margin-right: 80px;
  }
  .home__graphic-img {
    height: 280px;
  }
  .home__button-container {
    margin: 60px auto 0;
  }
}

@media only screen and (max-width: 320px) {
  .home__inner h1 {
    margin-right: 0;
  }
}

@primary-color: #0060b5;