.checkinreport__header {
  margin-bottom: 15px;
}

.checkinreport__header h3 {
  margin-bottom: 0;
}

.checkinreport__header small {
  color: gray;
  font-size: 12px;
}
