#components-layout-demo-side .logo {
  height: 32px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px;
}

.content__container {
  padding: 0 16px;
}

.dashboard__header {
  background: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 50px 0 15px;
  height: 80px;
}

.dashboard__header label {
  font-size: 16px;
  font-weight: bold;
}

.userlist__menu {
  margin-left: -16px;
  margin-right: -16px;
}

.dashboard__collapsed
  .ant-layout-sider-light
  .ant-layout-sider-zero-width-trigger {
  display: none;
}

.dashboard__list {
  margin-bottom: 15px;
  padding-top: 15px;
  position: relative;
  overflow: hidden;
}
