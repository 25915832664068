.checkin__loading {
  min-height: 200px;
}

.checkin__self {
  margin-top: 40px;
}

.checkin__result-icon {
  margin-bottom: 5px;
}

.checkin__self .ant-result-title {
  line-height: 1.1;
  margin: 20px 0 10px;
}

.checkin__buttons {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.checkin__btn {
  background-color: @primary-color;
  color: white;
  cursor: pointer;
  font-weight: bold;
  display: flex;
  flex: 1;
  padding: 20px;
  margin: 30px 10px;
  align-items: center;
  justify-content: center;
  font-size: 24px;
}

.checkin__reset {
  margin-top: 30px;
  text-align: center;
}

.checkin__results-container {
  display: flex;
  flex-direction: column;
}

.checkin__inline-spinner {
  color: 'blue';
  font-size: 20px;
  margin: 0 10px;
}

@primary-color: #0060b5;