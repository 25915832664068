.button {
  color: #fff;
  cursor: pointer;
  font-weight: bold;
  padding: 20px;
  background: @primary-color;
  border-radius: 11px;
  text-align: center;
  max-width: 280px;
  margin: 24px auto 36px auto;
}

.button-disabled {
  cursor: default;
  opacity: 0.3;
}

@primary-color: #0060b5;