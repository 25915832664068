.classdetails__cards {
  display: flex;
  flex-direction: row;
}
.classdetails_header {
  margin-top: 15px;
  margin-bottom: 0;
}
.classdetails__students {
  margin-top: 15px;
}
.classdetails_staff {
  margin-bottom: 10px;
}
.classdetails_staff:last-of-type {
  margin-bottom: 0;
}

.classdetails__cards h1 {
  margin-bottom: 0;
}

.classdetails__cards h2 {
  font-size: 1em;
}
