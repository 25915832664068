.screener__alert {
  margin: 15px 8px 0;
}

.screener__header {
  padding: 15px 72px;
  margin-bottom: 10px;
}

.screener__alert {
}

.screener__footer {
  margin: 30px 0 50px;
  text-align: center;
}

.screener__footer,
.screener__footer a {
  color: #aaa;
  font-weight: normal;
  font-size: 12px;
  padding-bottom: 60px;
}

.screener__alerts {
  margin: 0 auto 0 auto;
  max-width: 960px;
  padding: 20px 0;
}

.container-tree {
  background-color: #f5f5f7;
  border: 1px solid #e3e3e5;
  border-radius: 15px;
  margin: 0 auto 0 auto;
  max-width: 960px;
  padding: 20px;
}

.container-inner-tree {
  max-width: 532px;
  margin: 0 auto;
  width: 100%;
}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  .container-tree {
    border-radius: 0;
  }
  .screener__header {
    padding: 15px;
  }
}
