.screener__start-over {
  text-align: center;
  padding-bottom: 40px;
}

.screener__confirm-body ul {
  list-style-type: disc;
}

.screener__confirm-body ul li {
  margin: 0 0 8px 30px;
  padding: 0 0 0 6px;
}
