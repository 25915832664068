.screeneranswer__checkbox {
  display: flex;
}

.screeneranswer__label {
  display: flex;
  align-items: center;
}

.screeneranswers__number {
  margin-right: 15px;
}

.screeneranswers__options {
  margin: 15px 0 0 25px;
}

.screeneranswers__options .checkbox {
  margin-right: 10px;
}

.screenerquestionsform__answer-small {
  margin-left: 20px;
}

.screenerquestionsform__modal-section-header {
  margin-bottom: 20px;
}

.screenerquestionsform__modal h3 {
  margin-bottom: 0;
}

.screenerquestionsform__modal small {
  color: gray;
  font-size: 13px;
}

.screenerquestionsform__modal
  .ant-collapse-content
  > .ant-collapse-content-box {
  padding: 0;
}

.screenerquestionsform__modal
  .ant-collapse
  > .ant-collapse-item
  > .ant-collapse-header {
  padding-left: 20px;
  padding-top: 0;
  padding-bottom: 0;
  margin-bottom: 20px;
}

.screenerquestionsform__modal
  .ant-collapse
  > .ant-collapse-item-active
  > .ant-collapse-header {
  margin-bottom: 0;
}

.screenerquestionsform__modal
  .ant-collapse
  > .ant-collapse-item
  > .ant-collapse-header
  .ant-collapse-arrow {
  left: 0;
  padding-top: 0;
}

.screenerquestionsform__modal-answerinput {
  width: 680px;
  margin: 0 10px 0 0;
}
