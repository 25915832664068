.district__screener-question {
  display: flex;
  flex-direction: row;
  padding: 20px;
}

.district__screener-answer {
  margin-bottom: 3px;
}

.district__screener-question-icon {
  margin: 5px 20px 0 0;
  color: gray;
}

.district__screener-delete-button {
  margin-left: 15px;
}
