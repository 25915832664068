.dashboardhome__endingsoon {
  margin-top: 10px;
}

.dashboardhome__content .ant-statistic {
  font-weight: normal;
}


.userlist__col-center {
  text-align: center;
}
