.results__custom {
  margin: 0 20px 40px 20px;
}

.results__custom-row {
  padding: 15px;
}

.results__custom-row-and {
  font-weight: 600;
}

.results__custom-row-blue {
  background-color: #d0e2f2;
}

.results__custom-row-darkblue {
  background-color: #a6c2f1;
}

.results__custom-row-green {
  font-weight: 600;
  background-color: #d9ead4;
}

.results__custom-row-red {
  font-weight: 600;
  background-color: #f3cccd;
}

.results__custom-appendix {
  margin-bottom: 10px;
}
