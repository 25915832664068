.header-label {
  font-size: 28px;
  line-height: 32px;
  font-weight: 500;
  color: #1d1d1f;
  margin-block-end: 8px;
}

@media only screen and (max-width: 767px) {
  /* For mobile phones: */
  .header-label {
    font-size: 22px;
    line-height: 26px;
  }
}
