.screener__steps {
  margin-top: 10px;
  margin-bottom: 40px;
}
.screener__steps
  .ant-steps-item-process
  > .ant-steps-item-container
  > .ant-steps-item-tail::after,
.screener__steps
  .ant-steps-item-wait
  > .ant-steps-item-container
  > .ant-steps-item-tail::after,
.screener__steps
  .ant-steps-item-wait
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title::after,
.screener__steps
  .ant-steps-item-process
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title::after {
  background: #ccc;
}

.screener__steps .ant-steps-item-tail {
  margin-top: 2px;
}

.screener__steps .ant-steps-item-disabled {
  cursor: auto;
}

.screener__steps.ant-steps-small:not(.ant-steps-dot) .ant-steps-item-icon {
  margin-left: 0;
}

.screener__steps .ant-steps-item-tail {
  margin-left: 12px;
}

.screener__steps .ant-steps-item-content {
  width: auto;
}

@media only screen and (max-width: 768px) {
  .screener__steps {
    margin-top: 5px;
    margin-bottom: 15px;
  }
}
