.loggedinroute__alert {
  margin: 0 auto 30px auto;
  max-width: 800px;
}

.loggedinroute__container {
  background-color: #f5f5f7;
  border: 1px solid #e3e3e5;
  border-radius: 15px;
  margin: 0 auto 0 auto;
  max-width: 800px;
  min-height: 500px;
  padding: 20px;
}

.loggedinroute__inner {
  max-width: 750px;
  margin: 0 auto;
  padding: 40px;
  position: relative;
  width: 100%;
}
